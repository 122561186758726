import React from 'react';
import './error.css';

function Error(props) {
    return (
        <div className="container">
            <div className="error-card">
                <center>
                    <img
                        src="assets/images/error.svg"
                        alt="error"
                        className="error-image" />
                    <h1>Ops!</h1>
                </center>
                <p className="error-message">Invalid or expired tracking link.</p>
                <p className="debug-hidden-message">{props.error_message}</p>
            </div>
        </div>
    );
}

export default Error;
