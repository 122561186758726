import React from 'react';
import './card.css';

function Card(props) {
    return (
        <div className="card">
            <div className="">
                <img src={props.partner_image} alt="logo" className="card-logo" />
            </div>
            <div className="card-info">
                <i className="driver">{props.driver_name}</i>
                <br />
                <i className="partner">{props.partner_name}</i>
                <br />
                <i className="eta">{props.eta}</i>
            </div>
        </div>
    );
}
export default Card;
