const darkMapStyles = [
    {
        elementType: 'geometry',
        stylers: [{ color: '#333A40' }],
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{ color: '#333A40' }],
    },
    {
        featureType: 'landscape',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#151e26' }],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#a4a5a6' }],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#151e26' }],
    },
    {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#070a0d' }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }],
    },
    // {
    //   "featureType": "road.local",
    //   "stylers": [
    //     {"weight": 2}
    //   ]
    // },
    // {
    //   "featureType": "road.arterial",
    //   "stylers": [
    //     {"weight": 3}
    //   ]
    // },
    // {
    //   "featureType": "road.highway",
    //   "stylers": [
    //     {"weight": 4}
    //   ]
    // }
];

export default darkMapStyles;