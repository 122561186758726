import React from 'react';
import './loading.css';

function Loading(props) {
    return (
        <div className="loading">
            <img src={props.loading_image} alt="loading" className="loading-image" />
        </div>
    );
}
export default Loading;
