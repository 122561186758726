import React, {useEffect} from "react";
import Card from "./card/card";
import Map from "./map/map";
import Loading from "./loading/loading";
import Error from "./error/error";
import "./app.css";

const API_URL = process.env.REACT_APP_BASE_API_URL + "/track/";
const loadingImage = "assets/images/loader.gif";
const UPDATE_DELAY = 5000;

function App() {
    const id = new URLSearchParams(window.location.search).get('id');

    const [driverLocation, setDriverLocation] = React.useState();
    const [customerLocation, setCustomerLocation] = React.useState();

    // set loader image as
    const [partnerImage, setPartnerImage] = React.useState({loadingImage});
    const [driverName, setDriverName] = React.useState();
    const [partnerName, setPartnerName] = React.useState();
    const [eta, setETA] = React.useState();

    const [requestError, setRequestError] = React.useState();
    useEffect(() => {
        const getData = (firstCall) => {
            if (!id) {
                setRequestError("Missing id");
                return;
            }
            return fetch(`${API_URL}${id}`).then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return Promise.reject(res);
                }
            }).then((result) => {
                if (!result || result.error) {
                    return Promise.reject(result && result.error)
                }
                setCustomerLocation({
                    lat: result.order.latitude,
                    lng: result.order.longitude,
                });
                setDriverLocation({
                    lat: result.courier.latitude,
                    lng: result.courier.longitude,
                });
                setPartnerImage(result.order.account_img);
                setDriverName(result.courier.name);
                setPartnerName(result.order.account);
                setETA(result.courier.eta);
                scheduleGetData();
            }).catch(_error => {
                // We want it to fail only when it's the first call (the initial data load). If the page is already
                // showing some info, it will ignore errors.
                if (firstCall) {
                    setRequestError("Request Error");
                } else {
                    scheduleGetData();
                }
            });
        };
        const scheduleGetData = () => setTimeout(() => getData(), UPDATE_DELAY);

        return getData(true);
    }, [id]);

    return (
        <div>
            <div className="logo-div">
                <img src={process.env.PUBLIC_URL + "/assets/images/logo_dark.svg"} className="logo" alt="MetroSpeedy"/>
            </div>
            {process.env.REACT_APP_IS_DEMO === "true" && (<div className="demo">DEMO</div>)}
            {!driverName && !requestError && <Loading loading_image={loadingImage}/>}
            {!driverName && requestError && <Error error_message={requestError}/>}
            {driverName && (
                <Card
                    driver_name={driverName}
                    partner_name={partnerName}
                    eta={eta}
                    partner_image={partnerImage}
                />
            )}
            {driverName && (
                <Map
                    driverLocation={driverLocation}
                    customerLocation={customerLocation}
                />
            )}
        </div>
    );
}

export default App;
