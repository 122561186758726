import React from 'react';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import './map.css';
import darkMapStyles from './darkMapStyles';
// import lightMapStyles from './lightMapStyles';

const libraries = ['places'];

const mapContainerStyle = {
    width: '100vw',
    height: 'calc(100vh - 80px)',
};

const options = {
    styles: darkMapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

export default function Map(props) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries,
    });
    if (loadError) {
        return <div>Error Loading Maps</div>;
    }
    if (!isLoaded) {
        return <div>Loading Maps</div>;
    }
    return (
        <div className="map">
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={14}
                center={props.customerLocation}
                options={options}>
                <Marker
                    key="driver"
                    position={props.driverLocation}
                    icon={{
                        url: 'assets/images/purple_circle.svg',
                        scaledSize: new window.google.maps.Size(15, 15),
                    }} />
                <Marker
                    key="customer"
                    position={props.customerLocation}
                    icon={{
                        url: 'assets/images/purple_mark.svg',
                        scaledSize: new window.google.maps.Size(30, 30),
                    }} />
            </GoogleMap>
        </div>
    );
}
